<template>
    <centered-layout>
        <div class="text-center">
            <img
                src="@/assets/images/global/human_investigate.svg"
                width="200px"
                alt="Magnifying Glass with ID"
            >
        </div>
        <div class="text-center">
            <h5 class="fw-light">
                Looks like we found an existing application for you! Please click below to continue your prior application
            </h5>
        </div>
        <div class="d-grid">
            <button
                class="btn btn-secondary mt-3"
                @click="onclick"
            >
                Continue Prior Application
            </button>
        </div>
    </centered-layout>
</template>

<script>
    import CenteredLayout from '@/layouts/Centered'

    export default {
        components: {
            'centered-layout': CenteredLayout,
        },
        data() {
            return {
                returnToken2: this.$route.query?.p,
            }
        },
        mounted: function () {
            this.$logEvent('view_prior_applicant_redirection')
        },
        methods: {
            onclick: async function () {
                window.location.href = `/a/${this.returnToken2}`
            },
        },
    }
</script>
